class LambdaPushNotifications
{

    static supportsNotifications()
    {
        if (!("Notification" in window))
            return false;

        return true;
    }

    static hasPermission()
    {
        if (!LambdaPushNotifications.supportsNotifications())
            return false;

        if(Notification.permission == "granted")
            return true;
    
        if(Notification.permission == "denied")
            return false;

        return null;
    }

    static canRequestPermission()
    {
        if (!LambdaPushNotifications.supportsNotifications())
            return false;

        if(LambdaPushNotifications.hasPermission() === null)
            return true;

        return false;
    }

    static requestPermission(callback)
    {

        if (!LambdaPushNotifications.supportsNotifications())
            return;

        if(LambdaPushNotifications.hasPermission() === true)
            return;

        if(callback == null)
        {
            callback = function() {}
        }

        if(LambdaPushNotifications.canRequestPermission())
        {
            Notification.requestPermission().then((result) => {

                callback((result === 'granted'));

                if(result === "granted") {
                    var toast = LambdaToast.new().withIcon('fa-solid fa-bells').withTitle('Browser Notifications Enabled!').withBody('Thank you for enabling browser notifications, you will now get live notification updates').build().show();
                }
                else if(result == "denied") {
                    LambdaToast.new().withIcon('fa-solid fa-face-sad-tear').withTitle('Browser Notifications Denied').withBody(`We're sorry you decided to not enable browser notifications. If you change your mind, open the site settings in your browser`).build().show();
                }

            });
        }
    }

    static onNewNotification(data)
    {

        if(!LambdaPushNotifications.hasPermission())
            return;

        var api = data.api;

        var notif = new Notification(api.title, api);

        notif.onclick = function(e) {

            if(api.data.hasUrl)
            {
                e.preventDefault();
                window.open(api.data.url, "_blank");
            }

        };

    }

}

window.LambdaPushNotifications = LambdaPushNotifications;