import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: LambdaWebSocketConfig.Key,
    wsHost: LambdaWebSocketConfig.Hostname,
    wsPort: LambdaWebSocketConfig.Port ?? 80,
    wssPort: LambdaWebSocketConfig.Port ?? 443,
    forceTLS: (LambdaWebSocketConfig.Scheme ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

LocalUser.onSetup(() => {

    console.log("[REVERB] LocalUser setup, listening on user channel");

    window.Echo.private(`App.Models.User.${LocalUser.ID}`).notification((notif) => {

        LambdaPushNotifications.onNewNotification(notif);
    
    });

});

