class LambdaFriends
{
    static addFriend(id)
    {
        var window = new LambdaModal();
        window.setSize(LambdaModalSize.Large);
        window.setStaticBackdrop(true);
        window.setLoading();
        window.render();
        window.show();

        LambdaUtil.userBasicInfo(id, (user) => {

            window.setTitle(`<i class="fa-solid fa-user-plus"></i> Add Friend: ${user.name}`);
            window.setBody(`
                <div class="d-flex flex-row gap-2">
                    <div class="flex-grow-0 flex-shrink-0">
                        <img src="${user.avatar}" class="avatar-md border border-2 rounded-circle" alt="${user.name} avatar">
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1 flex-shrink-1">
                        <div>Are you sure you want to send a friend request to: <strong>${user.name}</strong>?</div>
                        <div>${user.name} will be sent a request, of which they can accept or decline.</div>
                        <div>You can remove any sent requests in your account settings.</div>
                    </div>
                </div>
            `);
            window.addCloseButton();
            window.addSuccessButton('Yes', () => {

                window.asyncPOST(route('async.friends.user.request', [id]), {}, true, true);

            });

        }, (response) => {

            window.showError(response.Message);
            window.addCloseButton();

        });

    }

    static removeFriend(id)
    {
        var window = new LambdaModal();
        window.setSize(LambdaModalSize.Large);
        window.setStaticBackdrop(true);
        window.setLoading();
        window.render();
        window.show();

        LambdaUtil.userBasicInfo(id, (user) => {

            window.setTitle(`<i class="fa-solid fa-user-plus"></i> Remove Friend: ${user.name}`);
            window.setBody(`
                <div class="d-flex flex-row gap-2">
                    <div class="flex-grow-0 flex-shrink-0">
                        <img src="${user.avatar}" class="avatar-md border border-2 rounded-circle" alt="${user.name} avatar">
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1 flex-shrink-1">
                        <div>Are you sure you want to remove <strong>${user.name}</strong> from your friends list?</div>
                        <div>${user.name} won't be notified of this.</div>
                        <form id="form_remove_friend">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" name="block_user" id="input_block_user">
                                <label class="form-check-label" for="input_block_user">
                                    Also add ${name} to my block list
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            `);

            var form = LambdaForms.from("form_remove_friend");
            form.onSubmit(data => {

                data['block_user'] = ($("#input_block_user").prop('checked')) ? 1 : 0;

                window.asyncPOST(route('async.account.friend.remove'), {
                    friend: id,
                    block: data['block_user']
                }, true, true);

            });

            window.addCloseButton();
            window.addSuccessButton('Yes', () => {

                form.submit();

            });

        }, (response) => {

            window.showError(response.Message);
            window.addCloseButton();

        });
    }

    static blockUser(id)
    {
        var window = new LambdaModal();
        window.setSize(LambdaModalSize.Large);
        window.setStaticBackdrop(true);
        window.setLoading();
        window.render();
        window.show();

        LambdaUtil.userBasicInfo(id, (user) => {

            window.setTitle(`<i class="fa-solid fa-ban"></i> Block User: ${user.name}`);
            window.setBody(`
                <div class="d-flex flex-row gap-2">
                    <div class="flex-grow-0 flex-shrink-0">
                        <img src="${user.avatar}" class="avatar-md border border-2 rounded-circle" alt="${user.name} avatar">
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1 flex-shrink-1">
                        <div>Are you sure that you want to block: <strong>${user.name}</strong>?</div>
                        <div>${user.name} will not be notified that you have blocked them, however if they are in your friends list, they will be removed.</div>
                        <div>You can manage your blocked users in account settings.</div>
                    </div>
                </div>
            `);
            window.addCloseButton();
            window.addSuccessButton('Yes', () => {

                window.asyncPOST(route('async.friends.user.block', [id]), {}, true, true);

            });

        },
        (response) => {

            window.showError(response.Message);
            window.addCloseButton();

        });

    }

}

window.LambdaFriends = LambdaFriends;