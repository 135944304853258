class Lambda
{

    static NotificationPanelOpen = false;

    static init()
    {
        Lambda.handleNotifications();
    }

    static handleNotifications()
    {

        var dropdown = document.getElementById("lambda-notif-toggle");
        dropdown.addEventListener('shown.bs.dropdown', event => {

            LambdaPushNotifications.requestPermission();

            Lambda.NotificationPanelOpen = true;

            Lambda.showNotifications();

        });

        dropdown.addEventListener('hidden.bs.dropdown', event => {

            Lambda.NotificationPanelOpen = false;

            Lambda.hideNotifications();

        });

    }

    static showNotifications()
    {
        $("#lambda-notification-load").removeClass("d-none");
        $("#lambda-notification-container").addClass("d-none");

        $.get(route('async.view.notifications'), data => {

            $("#lambda-notification-load").addClass("d-none");
            $("#lambda-notification-container").removeClass("d-none");
            $("#lambda-notification-list").html(data);

            LambdaHTTP.Post(route('async.notifications.markread'), true).OnSuccess(data => {

                $("#notifications-count-badge").fadeOut("slow");

            }).OnFail(data => {

                console.error(`Failed to mark notifications as read: ${data.Message} (Code: ${data.Code})`);

            }).Send();


        });

    }

    static hideNotifications()
    {
        $("#lambda-notification-load").removeClass("d-none");
        $("#lambda-notification-container").addClass("d-none");
        $("#lambda-notification-list").html(``);
    }

}

window.Lambda = Lambda;

$(document).ready(function() {

    Lambda.init();

});